import React from "react";
import * as styles from "./index.module.scss";
import { H_2 } from "../../components/typography/heading";
import { Translate } from "../../components/translate";
import { transPrefix } from "../../utils/helpers";
import { Page } from "../../components/page";
import { Container } from "../../components/container";
import { e_textAlign, e_textTag } from "../../components/typography/text";
import { Button } from "../../components/button";

const compPrefix = transPrefix("404");

const StyledNotFoundPage: React.FC = () => {
  return (
    <Page mainClass={styles.main} pageClass={styles.page}>
      <Container className={styles.content}>
        <H_2
          tag={e_textTag.h1}
          textAlign={e_textAlign.CENTER}
          className={styles.heading}
        >
          <Translate id={compPrefix("heading")} />
        </H_2>
        <Button type={"link"} to={"/"}>
          <Translate id={compPrefix("btn")} />
        </Button>
      </Container>
    </Page>
  );
};

export default StyledNotFoundPage